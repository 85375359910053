import { Collapse, Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { ControlDatePicker, ControlText, ControlTextArea, ControlSelect2, ControlUploadFile } from "../../helpers";
import { ControlSmallWrapper } from "../../helpers/ControlSmallWrapper";
import { AbmForm } from "../AbmForm";
import { useAbm } from "../../../services";

export const Form = React.forwardRef(({
  item, 
  actionsBottom = true, 
  schema, 
  onCancel, 
  onConfirm, 
  confirmLabel = "Guardar", 
  cancelLabel = "Cancelar", 
  title, 
  errorState, 
  data = {}}, ref) => {

  return (
    <AbmForm ref={ref} title={title} actionsTop={false} actionsBottom={actionsBottom} onSubmit={onConfirm} schema={schema} item={item} onCancel={onCancel} confirmLabel={confirmLabel} cancelLabel={cancelLabel} errorState={errorState}>
      {({onControlChange, entity, entityErrors}) => (
        <AbmFormContent {...{onControlChange, entity, entityErrors, data}}/>
      )}
    </AbmForm>
  );

})

const AbmFormContent = ({onControlChange, entity, entityErrors, data = {}}) => {

  const [items, setItems] = useState(data?.items);
  const [idActividad, setIdActividad] = useState(null);
  const [idMoneda, setIdMoneda] = useState(null);
  const entityIdItems = entity?.id_items;
  const itemsData = data?.items;
  const documentosService = useAbm({entity: "documentos"});

  useEffect(()=>{
    setItems(itemsData)
  }, [itemsData])

  useEffect(()=>{
    if(idActividad && idMoneda){
      setItems(data?.items.filter(item => item.id_actividad === idActividad && item.id_moneda === idMoneda));
      onControlChange({target: {name: "importe", value: importeTotal(entityIdItems)}});
      onControlChange({target: {name: "id_moneda", value: idMoneda}});
    }else{
      onControlChange({target: {name: "importe", value: ""}});
      onControlChange({target: {name: "id_moneda", value: null}});
      if(!entityIdItems || entityIdItems.length === 0){
        setItems(data?.items);
      }else{
        //console.log(entityIdItems)
        const item = data?.items?.find(item => parseInt(item.value) === entityIdItems[0]);
        if(!!item){
          setIdActividad(item.id_actividad);
          setIdMoneda(item.id_moneda);
        }
      }
    }
  }, [idActividad, idMoneda, entityIdItems, data])

  const importeTotal = (values) => {
    if(!values) return 0;
    return data?.items?.filter(item => values.indexOf(parseInt(item.value)) !== -1).reduce((prev, item) => prev + parseFloat(item.importe), 0)
  }

  const handleItemsChange = (event) => {
    onControlChange(event);
    if(!!event.target.value && event.target.value.length > 0){
      const item = data.items.find(item => parseInt(item.value) === event.target.value[0]);
      setIdActividad(item.id_actividad);
      setIdMoneda(item.id_moneda);
    }else{
      setIdActividad(null);
      setIdMoneda(null);
    }
  }

  const perceptor = !!data && !!entity && !!entity.id_perceptor && entity.id_perceptor !== 0 && data.perceptores && data.perceptores.find(perceptor => parseInt(perceptor.value) === entity.id_perceptor);
  const idPerceptor = perceptor && perceptor.value;
  const infoPerceptor = {
    height: !!perceptor ? 230 : 0
  }


  const designaciones = useMemo(() => {
    if(!data?.designaciones) return [];
    return data.designaciones.filter(designacion => designacion.id_actividad === idActividad);
  }, [idActividad, data?.designaciones]);


  useEffect(()=>{
    onControlChange({target: {name: "cbu_alias", value: perceptor ? perceptor.cbu_alias : null}});
    onControlChange({target: {name: "cuit", value: perceptor ? perceptor.cuit : null}});
    onControlChange({target: {name: "constancia_cbu", value: null}});
    onControlChange({target: {name: "constancia_fiscal", value: null}});
    onControlChange({target: {name: "id_designacion", value: null}});

    perceptor?.guid_documento_constancia_cbu && documentosService
      .getDocument(perceptor.guid_documento_constancia_cbu)
      .then(result => {
        onControlChange({target: {name: "constancia_cbu", value: {
          id: perceptor.id_documento_constancia_cbu,
          nombre: perceptor.nombre_documento_constancia_cbu,
          contenido: result,
          tipo: '',    
        }}});
      });

    perceptor?.guid_documento_constancia_fiscal && documentosService
      .getDocument(perceptor.guid_documento_constancia_fiscal)
      .then(result => {
        onControlChange({target: {name: "constancia_fiscal", value: {
          id: perceptor.id_documento_constancia_fiscal,
          nombre: perceptor.nombre_documento_constancia_fiscal,
          contenido: result,
          tipo: '',    
        }}});
      });

  }, [perceptor])

  return (
    <>
      <Grid item xs={12}>
        <ControlText name="numero" label="Número" autoFocus errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
      </Grid>  
      <Grid item xs={12}>
        <ControlDatePicker name="fecha_documento" label="Fecha" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
      </Grid>  
      <Grid item xs={12}>
        <ControlTextArea name="concepto" label="Concepto" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper}/>
      </Grid>  
      <Grid item xs={12}>
        <ControlSelect2 name="id_perceptor" label="Perceptor" errors={entityErrors} data={entity} onChange={onControlChange} options={data?.perceptores || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
      </Grid>  
      <Grid item xs={12} marginLeft={10} marginY={2}>
        <Collapse in={!!perceptor}>
          <Grid container>
            <Grid item xs={12}>  
                <ControlText name="cuit" label="CUIT" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
            </Grid>  
            <Grid item xs={12}>
                <ControlText name="cbu_alias" label="CBU / Alias" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
            </Grid> 
            <Grid item xs={12}>
              <ControlUploadFile name="constancia_cbu" label="Constancia de CBU" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
            </Grid>  
            <Grid item xs={12}>
              <ControlUploadFile name="constancia_fiscal" label="Constancia de Identificación Fiscal" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
            </Grid>  
          </Grid>
        </Collapse>
      </Grid>  
      <Grid item xs={12}>
        <ControlSelect2 name="id_moneda" label="Moneda" isDisabled errors={entityErrors} data={entity} onChange={onControlChange} options={data?.monedas || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
      </Grid>  
      <Grid item xs={12}>
        <ControlSelect2 name="id_items" label="Items" isDisabled={!!entity?.id} errors={entityErrors} data={entity} onChange={handleItemsChange} options={items || []} isMulti={true} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
      </Grid>  
      <Grid item xs={12}>
        <ControlText name="importe" label="Importe" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
      </Grid>  
      <Grid item xs={12}>
        <ControlSelect2 name="id_designacion" label="Designacion" errors={entityErrors} data={entity} onChange={onControlChange} options={designaciones || []} isMulti={false} closeMenuOnSelect={true}  Wrapper={ControlSmallWrapper}/>
      </Grid>  
      {/*<Grid item xs={12}>
        <ControlUploadFile name="designacion" label="Designación" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
      </Grid>*/}
      <Grid item xs={12}>
        <ControlUploadFile name="comprobante" label="Comprobante" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
      </Grid>  
      <Grid item xs={12}>
        <ControlUploadFile name="irpf" label="IRPF" errors={entityErrors} data={entity} onChange={onControlChange} Wrapper={ControlSmallWrapper} errorPlacement="bottom"/>
      </Grid>  
    </>
  )
}